import styled, { css } from "styled-components";
import { Color } from "../../layout/GlobalStyles/Color";
import { Dimensions } from "../../layout/GlobalStyles/Dimensions";

const baseButtonStyles = css`
    position: relative;
    border-radius: ${Dimensions.GridUnit * 0.5}px;
    padding: ${Dimensions.GridUnit}px 16px;
    font-size: ${Dimensions.GridUnit * 1.5}px;
    font-weight: 700;
    font-family: inherit;
    border-style: solid;
    border: 1px;
    display: inline-block;
    min-height: ${Dimensions.GridUnit * 4.5}px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

export const Primary = styled.button`
    ${baseButtonStyles}
    ${({ disabled }) =>
        disabled
            ? `
    background-color: ${Color.Neutral20};
    font-color: ${Color.Neutral60};
    `
            : `
    cursor: pointer;
    background-color: ${Color.Neutral100};
    border-color: ${Color.Neutral100};
    color: ${Color.Neutral0};
    transition: background-color 200ms ease;

    :hover {
        background-color: ${Color.Neutral90};
    }

    `};
`;
